<template>
  <div class="registerBox">
    <el-card class="boxCard">
      <div class="registerBox_title">巡查蝠后台管理系统</div>
      <el-form ref="ruleForm" :model="register" label-width="80px">
        <el-form-item label="手机号">
          <el-input placeholder="请输入11位手机号" v-model="register.mobile" class="input-with-select">
            <el-select v-model="register.country_code" slot="prepend" placeholder="请选择">
              <el-option label="+86" value="86"></el-option>
              <el-option label="+856" value="856"></el-option>
              <el-option label="+852" value="852"></el-option>
              <el-option label="+886" value="886"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input placeholder="请输入验证码" v-model="register.sms_code" class="input-with-select">
            <el-button
              slot="append"
              @click="postMsg"
              :disabled="timeNum===60?false:true"
            >{{this.timeNum===60?'发送短信':this.timeNum+'s后重发'}}</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="设置密码">
          <el-input
            placeholder="请输入密码"
            v-model="register.password"
            class="input-with-select"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input
            placeholder="请再次输入密码"
            v-model="queryPassword"
            class="input-with-select"
            type="password"
            @blur="checkPass"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" class="rester-button" @click="registerBtn">注册</el-button>
      <div class="checked">
        <el-checkbox v-model="checked"> </el-checkbox> <span> &nbsp;&nbsp;&nbsp;&nbsp;</span>我已阅并同意
        <a href="http://obs.i-patrol.cn/patrol_service_agreement.html" target="_blank">
          <span>《巡查蝠软件许可使用协议》</span>
        </a>
      </div>
      <el-link type="primary" @click="toLogin">已有帐号，点我登陆</el-link>
    </el-card>
  </div>
</template>

<script>
import { isTelAvailable } from "../../utils/verification";
import { account } from "../../utils/api";
import qs from "qs";
export default {
  name:"register",
  data() {
    return {
      register: {
        mobile: "",
        country_code: "86",
        sms_code: "",
        password: "",
        ticket: "",
        randstr: "",
      },
      queryPassword: "",
      checked: false,
      timeNum: 60,
      timer60: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.timer60);
    this.timer60 = null;
  },
  components: {},

  computed: {},

  methods: {
    toLogin() {
      this.$router.go(-1);
    },
    async validateFu({ ret, ticket, randstr }) {
      if (ret === 0) {
        this.register.ticket = ticket;
        this.register.randstr = randstr;
        this.timeDown();
        this.getSmsCOde();
      }
    },
    timeDown() {
      let that = this;
      this.timer60 = setInterval(function () {
        that.timeNum--;
        that.timeNum = that.timeNum < 10 ? "0" + that.timeNum : that.timeNum;
        if (that.timeNum == 0) {
          clearInterval(that.timer60);
          that.timeNum = 60;
        }
      }, 1000);
    },
    postMsg() {
      if (isTelAvailable(this.register.mobile)) {
        //显示 腾讯防水墙
        this.captcha.show();
      } else {
        this.$message.error("请填写正确的手机号");
      }
    },
    // 点击注册按钮
    async registerBtn() {
      for (var i in this.register) {
        if (this.register[i] === "") {
          return this.$message.error("请正确填写注册信息");
        }
      }
      if (!this.checked) {
        return this.$message.error("请阅读并同意相关协议");
      }
      const res1 = await this.$http.post(account.mobileExistence,{
        mobile:this.register.mobile,
        country_code:this.register.country_code
      })
      if(res1.data.existence){
        let that = this;
        this.$message.success('该账号已注册，3秒后将前往登录页面')
         setTimeout(function () {
          that.$router.push("login");
        }, 3000);
        return
      }
      let res = await this.$http.post(account.register, this.register);
      if (res.code === 0) {
        let that = this;
        setTimeout(function () {
          that.$router.push("login");
        }, 3000);
      }
    },
    // 失去焦点事件 用于确认密码是否一致
    checkPass() {
      if (this.register.password !== this.queryPassword) {
        this.$message.error("两次密码输入不一致，请检查密码");
      }
    },
    // 获取验证码后回调
    async getSmsCOde() {
      // let par = "?" + qs.stringify(this.register);
      let par = `?mobile=${this.register.mobile}&country_code=${this.register.country_code}&ticket=${this.register.ticket}&randstr=${this.register.randstr}`;
      const res = await this.$http.get(account.smsCode + par);
    },
  },
  mounted() {
    // 初始化腾讯防水墙
    this.captcha = new TencentCaptcha("2091202582", this.validateFu);
    //  防水墙验证后回调函数
  },
};
</script>
<style >
.el-input {
  width: 280px;
}
.el-select .el-input {
  width: 80px;
}
</style>
<style lang='scss' scoped>
.registerBox {
  @include flex_center;
  width: 100vw;
  height: 100vh;
  .registerBox_title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 28px;
    text-align: center;
    margin-bottom: 30px;
  }
  .el-card {
    //  @include flex_center_col;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 830px;
    height: 542px;
    .el-form-item .el-button {
      background: $myColor;
      color: #ffffff;
      font-weight: 700;
    }
    .rester-button {
      width: 360px;
      height: 40px;
      text-align: center;
      background: linear-gradient(180deg, #38aff4 0%, #1477e1 100%);
      box-shadow: 0px 0px 10px 0px rgba(0, 101, 201, 0.37);
      border-radius: 4px;
      margin-top: 30px;
    }
    .checked {
      margin-top: 30px;
    }
    .el-link {
      position: absolute;
      right: 25px;
      bottom: 25px;
    }
  }
}
a {
  text-decoration: none;
  color: $myColor;
}
</style>
